<template>
  <tr class="f12">
    <td class="text-truncate" :style="`max-width: ${366 - (match.games.length * 30)}px`">
          <i
            v-for="(slot) in item.slots"
            :class="`fas fa-circle ${teamColors[slot - 1]} 'white--text'`"
            :key="slot"
          ></i> {{names | formatArray}}
    </td>
    <td v-for="(g,i) in match.games" :key="`${match.number}-${i}`" style="max-width:30px"></td>
  </tr>
</template>

<script>
import flatten from '../../../helpers/ArrayFlatten'
export default {
  props: ['item', 'match', 'games', 'editing', 'winLoss', 'division', 'teamColors'],
  computed: {
    iTeams () {
      return this.item.teams && this.item.teams.filter(f => !!f)
    },
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    seed () {
      return this.item.team ? this.item.team.seed : null
    },
    useTeamNames () {
      return this.division.useTeamNames
    },
    names () {
      if (this.xs) {
        if (this.division.numOfPlayers <= 2 && !this.division.useTeamNames) {
          return this.playerNames
        }
        return this.iTeams.map(t => t.name)
      }
      // Not XS
      return [this.teamNames.join(' / ')]
    },
    playerNames () {
      return this.iTeams && flatten(this.iTeams.map(t => t.playerNames))
    },
    teamNames () {
      return this.iTeams && this.iTeams.filter(f => !!f).map(m => m.name)
    }
  }
}
</script>
