<template>
  <v-card class="ma-1 pa-1 f12">
    <v-row dense>
      <v-col cols="6">
        {{match.startTime ? match.startTime.format('ddd h:mm A') : ''}}
      </v-col>
      <v-col class="text-right" cols="6">
        Court: {{match.court}}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <table width="100%">
          <thead>
            <tr>
              <th class="text-left" :style="`max-width: ${366 - (match.games.length * 30)}px`">Team</th>
              <th v-for="(g,i) in match.games" :key="`${match.number}-${i}`" style="max-width:30px">{{ `Set ${i + 1}` }}</th>
            </tr>
          </thead>
          <tbody>
            <printable-team-row
              v-for="team in teams" :key="team.map"
              :item="team"
              :match="match"
              :division="division"
              :teamColors="teamColors"
            ></printable-team-row>
          </tbody>
        </table>
      </v-col>
      <v-col class="text-right" cols="12" >
        Ref:
        <template v-if="match.refTeam">
          <i :class="`fas fa-circle ${teamColors[match.refTeam.slot - 1]} 'white--text'}`"></i> {{teamName(match.refTeam)}}
        </template>
        <div v-else style="width: 100px; display: inline-block;">&nbsp;</div>
      </v-col>
    </v-row>
  </v-card>

</template>

<script>
import PrintableTeamRow from './PrintableTeamRow'
import { mapGetters } from 'vuex'

export default {
  props: ['match', 'division', 'pool', 'teamColors'],
  computed: {
    ...mapGetters(['getTeam']),
    games () {
      return this.match.games
    },
    awayTeam () {
      return this.match.awayTeam ? this.getTeam(this.match.awayTeam.teamId) : null
    },
    homeTeam () {
      return this.match.homeTeam ? this.getTeam(this.match.homeTeam.teamId) : null
    },
    teams () {
      const t = []
      // home
      if (this.match.homeTeam) {
        t.push({
          slots: [this.match.homeTeam.slot],
          teams: [this.homeTeam],
          winner: this.match.winner === 'home',
          map: 'home',
          seed: this.homeSeed
        })
      } else if (this.match.homeTeamIds) {
        t.push({
          kob: true,
          slots: this.match.homeTeamIds.map(m => this.pool.getSlot(m)),
          teams: this.match.homeTeamIds.map(m => this.getTeam(m)),
          winner: this.match.winner === 'home',
          map: 'home'
        })
      } else {
        t.push({
          holder: this.match.homeMap
        })
      }
      // away
      if (this.match.awayTeam) {
        t.push({
          slots: [this.match.awayTeam.slot],
          teams: [this.awayTeam],
          winner: this.match.winner === 'away',
          map: 'away',
          seed: this.awaySeed
        })
      } else if (this.match.awayTeamIds) {
        t.push({
          kob: true,
          slots: this.match.awayTeamIds.map(m => this.pool.getSlot(m)),
          teams: this.match.awayTeamIds.map(m => this.getTeam(m)),
          winner: this.match.winner === 'away',
          map: 'away'
        })
      } else {
        t.push({
          holder: this.match.awayMap
        })
      }
      return t
    },
    useTeamNames () {
      return this.division.useTeamNames
    }
  },
  methods: {
    teamName (team) {
      var x = this.getTeam(team.teamId)
      return x ? x.name : null
    }
  },
  components: {
    PrintableTeamRow
  }
}
</script>
<style scoped>
.pool {
  page-break-after: always;
  font-size: 12px;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
table thead tr th {
  color: rgba(0,0,0,0.54);
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(0,0,0,0.12);
}
table thead tr th:not(:first-child) {
  padding: 0 !important;
}
td, th {
  padding-left: 3px;
}
table tbody tr td {
  font-weight: 700;
  border: 1px solid rgba(0,0,0,0.12);
}
table tbody tr td .subheader {
  color: rgba(0,0,0,0.54);
  font-weight: 300;
}
table table tr td {
  border: none;
}
.tournament td {
  font-size: 9pt;
}
</style>
