<template>
  <v-card flat class="pool f12">
    <v-container fluid>
      <v-row dense class="mb-4" v-if="!isLeague">
        <v-col cols="12">
          <table width="100%">
            <thead>
              <tr>
                <th class="text-left">Tournament</th>
                <th>Date</th>
                <th>Division</th>
                <th>Pool</th>
                <th>{{pool.courts && pool.courts.split(',').length > 1 ? 'Courts' : 'Court'}}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tournament">
                <td>{{tournament.name}}</td>
                <td class="text-center">{{round.date | readableDate}}</td>
                <td class="text-center">{{division.name}}</td>
                <td class="text-center">{{pool.name}}</td>
                <td class="text-center">{{pool.courts | formatArray}}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <table width="100%">
            <thead>
              <tr>
                <th class="text-left">League</th>
                <th>Date</th>
                <th>Division</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tournament">
                <td>{{tournament.name}} Week {{$route.query.round}}</td>
                <td class="text-center">{{round.date | readableDate}}</td>
                <td class="text-center">{{division.name}}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row dense v-if="!isLeague">
        <v-col cols="12">
          <table width="100%" class="print-table">
            <thead>
              <tr>
                <th rowspan="2" class="text-left">Name</th>
                <th colspan="2" v-if="pool.setting && pool.setting.isMatch">Match</th>
                <th colspan="2">Set</th>
                <th colspan="2">Points</th>
              </tr>
              <tr>
                <th width="60" v-if="pool.setting && pool.setting.isMatch">Wins</th>
                <th width="60" v-if="pool.setting && pool.setting.isMatch">Losses</th>
                <th width="60">Wins</th>
                <th width="60">Losses</th>
                <th width="60">For</th>
                <th width="60">Against</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="team in teams" :key="team.teamId">
                <td class="text-left" width="50%">
                  <table>
                    <tr>
                      <td rowspan="2"><i :class="'fas fa-circle '  + teamColors[team.slot - 1]"></i></td>
                      <td>{{team.name}}</td>
                    </tr>
                    <tr v-if="!noRep">
                      <td class="subheader text-truncate">
                        {{team.repping}}
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="text-center" v-if="pool.setting && pool.setting.isMatch"></td>
                <td class="text-center" v-if="pool.setting && pool.setting.isMatch"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <!-- Match style alert -->
      <v-row dense>
        <v-col cols="12">
          <v-alert
            :value="true"
            type="info"
            class="mb-1"
          >
            All matches are {{pool.matchDescription}}.
          </v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" v-for="match in pool.matches" :key="match.number">
          <printable-card-league
            :match="match"
            :division="division"
            :pool="pool"
            :teamColors="teamColors"
            v-if="isLeague"
          ></printable-card-league>
          <printable-card
            v-else
            :match="match"
            :division="division"
            :pool="pool"
            :teamColors="teamColors"
          ></printable-card>
        </v-col>
      </v-row>
      <v-row class="justify-center" dense>
        <div><b>Enter all score on line using the QR Code below</b></div>
      </v-row>
      <v-row class="justify-center" dense>
        <qrcode-vue :value="qrCodeV" :size="100" level="H" />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import firstBy from 'thenby'
import { mapGetters } from 'vuex'
import PrintableCard from '@/components/Tournament/Match/PrintableCard'
import PrintableCardLeague from '@/components/Tournament/Match/PrintableCardLeague'
import QrcodeVue from 'qrcode.vue'
import flatten from '@/helpers/ArrayFlatten'
import { qrCodeBase } from '@/classes/_URL'

export default {
  mixins: [RouteMixin],
  props: ['pool'],
  computed: {
    ...mapGetters(['getTeam', 'tournament', 'getDivision', 'teamColors']),
    division () {
      return this.getDivision(this.divisionId)
    },
    qrCodeV () {
      if (this.isLeague) return window.location.href
      const params = this.getParams(this.pool.id)
      return this.getPoolURL(params)
    },
    src () {
      return `${qrCodeBase}&url=${this.qrCodeV}`
    },
    isLeague () {
      return this.$route.name === 'league-play'
    },
    round () {
      if (!this.division) return null
      if (this.$route.name === 'league-play' && this.$route.query.round) {
        return this.division.days[this.$route.query.round - 1]
      }
      return this.division.days.find(f => f.id === this.dayId)
    },
    settings () {
      return null
    },
    teams () {
      const teams = this.pool.teams.map(t => {
        var x = this.getTeam(t.teamId)
        return {
          teamId: x.id,
          slot: t.slot,
          seed: t.roundSeed || x.seed,
          repping: x.getRep(this.division.repField),
          adminRep: t.adminRep,
          name: x.name
        }
      })
      return teams.sort(firstBy('slot'))
    },
    noRep () {
      return this.division && this.division.props.includes('noRep')
    }
  },
  methods: {
    teamName (team) {
      var x = this.getTeam(team.teamId)
      return x ? x.name : null
    },
    getParams (poolId) {
      var params = flatten(this.tournament.divisions.map(div => {
        return div.days.map(da => {
          var pool = da.flights && da.flights[0] && da.flights[0].pools.find(p => p.id === poolId)
          return pool && {
            tournament: this.tournament,
            division: div,
            day: da,
            pool: pool
          }
        })
      }))
      return params.find(f => f)
    },
    getPoolURL (params) {
      const a = document.createElement('a')
      a.href = this.$router.resolve({ name: 'pool-sheet', params: { dayId: params.day.id, divisionId: params.division.id, poolId: params.pool.id, tournamentId: params.tournament.id } }).href
      return a.href
    }
  },
  components: {
    PrintableCard,
    PrintableCardLeague,
    QrcodeVue
  }
}
</script>

<style scoped>
.pool >>> {
  page-break-after: always;
  font-size: 12px;
}
>>> table {
  border-spacing: 0;
  border-collapse: collapse;
}
>>> table thead tr th {
  color: rgba(0,0,0,0.54);
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(0,0,0,0.12);
}
>>> table thead tr th:not(:first-child) {
  padding: 0 !important;
}
>>> td, th {
  padding-left: 3px;
}
>>> table tbody tr td {
  font-weight: 700;
  border: 1px solid rgba(0,0,0,0.12);
}
>>> table tbody tr td .subheader {
  color: rgba(0,0,0,0.54);
  font-weight: 300;
}
>>> table table tr td {
  border: none;
}
>>> .tournament td {
  font-size: 9pt;
}
</style>
